.footer {
  display: flex;
  margin-top: 1rem;
  color: var(--art-color-text-secondary);

  > div:before {
    content: '|';
    margin: 0.8rem;
  }
}
