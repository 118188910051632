.header {
  text-align: center;
}

.header > img {
  width: 208px;
  margin: 1.5em 0;
}

.formHeadingContainer {
  margin-bottom: 2rem;
}

.formHeading {
  vertical-align: top;
  margin-left: 12px;
  font-weight: 700;
}
