
.modal {
  padding: 1rem;
  min-width: 550px;
  z-index: 50;
  border-radius: 0.5rem;
  background-color: white;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalShadow {
  position: fixed;
  inset: 0px;
  height: 100vh;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 10;
}
