ul.warningMessageContainer {
  background-color: rgba(var(--color-blue-50), 1);
  padding: 1em;
  border-radius: 0.25em;
  list-style: none;
  margin-top: 0;
}

ul.warningMessageContainer li {
  margin-bottom: 0.25em;
}

.warningMessageLabel {
  margin-bottom: 1em;
  font-weight: 600;
}
