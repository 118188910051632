.buttonContainer {
  display: flex;

  flex: 1;
  justify-content:center;
  margin-bottom: 2.5rem;
}

.button {
  background-color: transparent;
  margin-right: 1rem;
  border: 0;
  padding: 0.25em 1em;
  color: rgba(var(--color-black), 1);
  font-weight: 500;
}

.button:last-child {
  margin-right: 0;
}

.button:hover {
  cursor: pointer;
}

.button:active {
  border-color: rgba(var(--color-black), .7);
}

.spanActive {
  border-bottom: solid transparent 3px;
  border-color: rgba(var(--color-blue-100), 1);
  padding-bottom: .25rem
}
