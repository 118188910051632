.filePickerLabel {
  display: flex;

  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: 1px solid rgba(var(--color-gray-decorative), 1);
  margin-bottom: 0.5em;
}

.selectFilePickerLabelText {
  flex: 1;
  color: rgba(var(--color-gray), 1);
  cursor: pointer;
}

.filePickerLabel:hover .selectFilePickerLabelText {
  color: rgba(var(--color-blue-200), 1);
}

.filePickerLabel:active .selectFilePickerLabelText {
  color: rgba(var(--color-blue-400), 1);
}

.filePickerLabel:hover .selectFileButton {
  color: rgba(var(--color-blue-200), 1);
}

.filePickerLabel:active .selectFileButton {
  color: rgba(var(--color-blue-400), 1);
}
