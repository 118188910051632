@import "../../scss/inputs.scss";

.reportForm {
  display: flex;
  flex-direction: column;
  padding: 0 2em;
}

.section {
  display: flex;
  flex-direction: column;
}

.sectionLabel {
  font-weight: 500;
  margin-top: 0.5em;
}

.reportForm input[type="radio"] {
  width: auto;
  margin: 0px .5rem 15px 2rem;
}

.filePickerContainer {
  margin: .5rem 0;
}

.reportForm textarea {
  margin-top: var(--art-space-0-5);
  min-height: 2.5rem;
  resize: vertical;
}

.submitButton {
  margin-top: 1em;
  border: none;
  border-radius: var(--button-radius);
  padding: .5rem 1.5rem;
  background: var(--art-color-mono-black);
  color: rgba(var(--color-white), 1);
  align-self: center;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  font-family: var(--art-font-family-body);
}

.submitButton:hover {
  box-shadow: 0 0 0 1px rgba(var(--color-blue-100), 1);
}

.submitButton:active {
  box-shadow: inset 0 0 4px 1px rgba(var(--color-blue-200), 1);
}
